import React, { ReactChild } from 'react';
import { useTableOrMobile } from '../../hooks';
import { cannonStyled } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';

const AnchorSuccessStyle = cannonStyled(
  'a',
  ({ $theme, isMobile }: { $theme: CannonSafeThemeT; isMobile: boolean }) => ({
    fontFamily: 'Titillium Web',
    backgroundColor: $theme.cannonColors.primaryBlue,
    border: 'none',
    color: $theme.cannonColors.primaryWhite,
    padding: '12px 18px',
    display:'inline-block',
    width: isMobile ? '95%' : 'auto',
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    borderRadius: '8px',
    fontSize: '19px',
    ':hover': {
      backgroundColor: $theme.cannonColors.primaryBlueLight,
    },
  })
);

const AnchorSuccess = (props) => {
  const [isMobile, isTablet] = useTableOrMobile();
  return (
    <AnchorSuccessStyle href={props.href} isMobile={isMobile}>
      {props.children}
    </AnchorSuccessStyle>
  );
};

export default AnchorSuccess;
