import React, { useEffect, useState } from 'react';
import { useCoreAPIURL, useTableOrMobile } from '../../../hooks';
import { IKeyReplacementDetails } from '../../../pages/keys-replacement';
import { cannonStyled, useCannonStyletron } from '../../../theme';
import LostAssistanceForm, { ILostAssistanceForm } from '../LostAssistanceForm';
import StepsForForm from './components/StepsForForm';
import TitleAndSubtitleAssitance from './components/TitleAndSubtitleAssitance';
import scrollTo from 'gatsby-plugin-smoothscroll';
import SuccesfullyRegistered from './components/SuccesfullyRegistered';
import { RoundedButton } from '../../../atoms/Button';

const KeyReplacementContainer = cannonStyled(
  'div',
  ({ formSubmited }: { formSubmited: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: formSubmited ? 0 : '50px',
  })
);

const ReducedContainer = cannonStyled('div', ({ isMobile }: { isMobile }) => ({
  maxWidth: isMobile ? '90%' : '60%',
  marginTop: '20px 0 0 0',
}));

const KeyReplacement = (props: IKeyReplacementDetails) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();

  // STATE
  const coreAPI_URL = useCoreAPIURL();
  const [email, setEmail] = useState('');
  const [formSubmited, setFormSubmited] = useState(false);

  useEffect(() => {
    if (formSubmited === true) {
      window.location.hash = 'submited';
    }
  }, [formSubmited]);

  // HANLDERS && FUNCTIONS

  async function handleFormSubmited(value: ILostAssistanceForm) {
    setFormSubmited(true);
    scrollTo('#main');
    value.comesFrom = 'KeyReplacement';
    const response = await fetch(coreAPI_URL + '/lost-combination', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        return r;
      })
      .catch((e) => {});
  }

  return (
    <KeyReplacementContainer formSubmited={formSubmited} id='main'>
      {!formSubmited ? (
        <>
          <TitleAndSubtitleAssitance
            title={props.title}
            subTitle={props.subTitle}
          />
          <ReducedContainer isMobile={isMobile}>
            <StepsForForm steps={props.steps} note={props.note} />
            {/* <LostAssistanceForm
              onFormSentSuccesfully={(value) => {
                handleFormSubmited(value);
                setEmail(value.email);
              }}
            /> */}
            <RoundedButton
              onClick={() => {
                window.location.href =
                  'https://customersupport.cannonsafe.com/portal/en/newticket';
              }}
              clean
              color='secondary'
              size='compact'
              $style={{ margin: '80px 0', padding: '20px 50px' }}
            >
              Open a Ticket
            </RoundedButton>
          </ReducedContainer>
        </>
      ) : (
        <SuccesfullyRegistered email={email} />
      )}
    </KeyReplacementContainer>
  );
};

export default KeyReplacement;
