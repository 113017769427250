import React, { useEffect } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { cannonStyled } from '../theme';
import { CannonBreadcrumbs } from '../atoms';
import { ResponsiveContainer } from '../atoms/Container';
import { useTableOrMobile } from '../hooks';
import { KeyAndCombinationForm } from '../components/sections';
import KeyReplacement from '../components/sections/KeyAndCombinationForm/KeyReplacement';
import TitleAndSubtitleAssitance from '../components/sections/KeyAndCombinationForm/components/TitleAndSubtitleAssitance';

const query = graphql`
  query {
    strapiKeyReplacement {
      title
      subTitle
      steps
      note
    }
  }
`;
export interface IKeyReplacementDetails {
  title: string;
  subTitle: string;
  steps: string;
  note: string;
}
interface IKeyReplacement {
  strapiKeyReplacement: IKeyReplacementDetails;
}

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '16px' : '75px',
    width: '380px',
  })
);

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '100px 10px' : '50px 10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

const KeysReplacement: React.FC<PageProps> = (props: PageProps) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const data = useStaticQuery<IKeyReplacement>(query);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     // 👇️ redirects to an external URL
  //     window.location.replace('/contact-us/');
  //   }, 1500);

  //   return () => clearTimeout(timeout);
  // }, []);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              { title: 'Keys Replacement', href: '/keys-replacement' },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <KeyReplacement
            title={data.strapiKeyReplacement.title}
            subTitle={data.strapiKeyReplacement.subTitle}
            steps={data.strapiKeyReplacement.steps}
            note={data.strapiKeyReplacement.note}
          />
          {/* <TitleAndSubtitleAssitance
            title={data.strapiKeyReplacement.title}
            subTitle={'Coming Soon'}
          /> */}
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default KeysReplacement;
