import React from 'react';
import ReactMarkdown from 'react-markdown';
import { cannonStyled, TWH4, useCannonStyletron } from '../../../../theme';
import { CannonSafeThemeT } from '../../../../theme/CannonSafesTheme';

interface IStepsForForm {
  steps: string;
  note: string;
}

const AnchorTxt = cannonStyled(
  'a',
  ({ $theme }: { $theme?: CannonSafeThemeT }) => ({
    color: $theme?.cannonColors.primaryBlue,
    textDecoration: 'underline',
    fontWeight: 400,
    ':hover': {
      textDecoration: 'none',
    },
  })
);

const NotesContainer = cannonStyled(
  'div',
  ({ $theme }: { $theme?: CannonSafeThemeT }) => ({
    backgroundColor: $theme?.cannonColors.primaryWhiteDark,
    padding: '20px',
    margin: '40px 0',
  })
);

const StepsForForm = (props: IStepsForForm) => {
  const [css, theme] = useCannonStyletron();

  return (
    <>
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => (
            <TWH4
              color={theme.cannonColors.secondarySilver}
              weight={400}
              align={'left'}
              $style={{ fontSize: '16px', marginBottom: '22px' }}
              {...props}
            />
          ),
          strong: ({ node, ...props }) => (
            <TWH4
              align={'left'}
              color={theme.cannonColors.primaryBlue}
              weight={700}
              $style={{ fontSize: '18px', margin: '12px 0 6px 0' }}
              {...props}
            />
          ),
          a: ({ node, ...props }) => <AnchorTxt {...props} />,
          ul: ({ node, ...props }) => (
            <TWH4
              color={theme.cannonColors.secondarySilver}
              weight={400}
              align={'left'}
              $style={{ fontSize: '16px', marginBottom: '22px', marginLeft: '40px', }}
              {...props}
            />
          ),
        }}
      >
        {props.steps}
      </ReactMarkdown>
      {props.note != '/' ? (
        <NotesContainer>
          <TWH4
            color={theme.cannonColors.primaryBlue}
            weight={400}
            align={'left'}
            $style={{ fontSize: '16px' }}
          >
            {props.note}
          </TWH4>
        </NotesContainer>
      ) : (
        <p></p>
      )}
      <TWH4
        color={theme.cannonColors.secondarySilver}
        weight={400}
        align={'left'}
        $style={{ fontSize: '16px' }}
      >
        California Residents must adhere to{' '}
        <AnchorTxt
          href='https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CIV&sectionNum=1189'
          target={'_blank'}
        >
          Civil Code 1189
        </AnchorTxt>
      </TWH4>
    </>
  );
};

export default StepsForForm;
